import React from "react";
import "./App.css";

import RoutesApp from "./controler/routes/router";

export default function App() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <RoutesApp />;
    </div>
  );
}
