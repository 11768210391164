import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LANDING_PAGE_TOMATO } from "./routerConstants";

const publicRoutes = [
  {
    path: LANDING_PAGE_TOMATO.MAIN,
    exact: true,
    component: lazy(() => import("../../views/tomato/landing_page/index")),
  },
];

export default function RoutesApp() {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
